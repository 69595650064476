import Vue from 'vue'
import App from './App.vue'
//引入路由
import router from '@/router';
import store from '@/store';

//这里是入口文件，也是程序最先执行的地方
//注册全局组件---三级联动
// import Pagination from '@/components/Pagination';
//全局组件注册方式:
//第一参数:组件名字  第二个参数：哪一个组件
//elementUI注册组件其中有一种写法，就是这种写法
// Vue.component(Pagination.name,Pagination);
//引入先关的mock数据的文件【需要代码执行一次】
// import "@/mock/serve";
//引入swiper需要使用的样式[项目当中用到swiper的地方很多，样式引入一次即可
import "swiper/css/swiper.css"

//引入全部的请求函数
import * as API from '@/api';

import "@/assets/style/index.less"; 
import { formatMoneyString,numberToChinese } from "@/utils/publicFunction";


/******************************************************************* */
//elementUI
//这种引入方式----引入全部的组件
import ElementUI from 'element-ui';
import moment from 'moment'
// import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/theme/index.css';



import Pagination from "@/components/Pagination";


Vue.use(ElementUI);
Vue.use(Pagination);
// Vue.use(moment);
Vue.prototype.moment = moment;
/*********************************************************************/

// 全局注册金额添加千分符方法
Vue.prototype.formatMoneyString = formatMoneyString;
// Vue.prototype.download = download;
// 全局注册将阿拉伯数字转中文写法1,2,3->一，二，三
Vue.prototype.numberToChinese = numberToChinese;
Vue.prototype.$EventBus = new Vue();

//引入自定义插件
import jch from '@/plugins/jch';
//Vue.use使用这个插件的时候，会出发自定义插件对象的install方法
Vue.use(jch);

// 引入socket

// Vue.config.productionTip = false
// import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client'
 
// const SOCKETIO = new VueSocketIO({
//   debug: true, // true开启
//   connection: SocketIO('ws://192.168.13.182:8088'), // 里面写socket服务器地址
//   options: {
//     autoConnect: false // 关闭自动连接，一般是在用户登录过后才进行手动连接
//   }
// })
 
// Vue.use(SOCKETIO)

// 引入icon
import '@/assets/icons'



// 验证插件
// import '@/plugins/valadiate'

//创建Vue类的一个实例
new Vue({
  render: h => h(App),
  beforeCreate() {
    //配置全局事件总线
    Vue.prototype.$bus = this;
    //把全部的请求函数：作为Vue.prototype的属性，组件实例可以获取
    //请求函数只需要注册一次，可以在组件当中使用。
    Vue.prototype.$API = API;
  }
  ,
  //注册路由,给组件的身上添加了$router与$route两个属性
  router,
  //注册仓库，给每一个组件的身上添加$store属性
  store,
  created() {
    // 在根组件的 created 钩子中触发 Vuex 的某个动作
    this.$store.dispatch('GetAreaList');
  }
}).$mount('#app');
