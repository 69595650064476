<template>
  <el-dialog title="" :visible.sync="dialogRegister.dialog" width="500px">
    <div class="login-container">
      <!-- <div class="title">
        {{ dialogRegister.zczh ? "注册账号" : "重置密码" }}
      </div> -->
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="dialogRegister.zczh ? '注册账号' : '重置密码'"
          name="mima"
        />
      </el-tabs>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        label-position="top"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model.number="ruleForm.username">
            <em slot="prefix">
              <SvgIcon iconClass="person" /> </em
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password" show-password>
            <em slot="prefix"> <SvgIcon iconClass="password" /> </em
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password1">
          <el-input type="password" v-model="ruleForm.password1" show-password>
            <em slot="prefix"> <SvgIcon iconClass="password" /> </em
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <div class="flexBox">
            <el-input
              @keyup.enter="submitForm('ruleForm')"
              v-model="ruleForm.code"
              style="width: 270px; margin-right: 30px"
            >
              <em slot="prefix">
                <SvgIcon iconClass="yzm" />
              </em>
            </el-input>
            <div class="imgs" @click="getCode">
              <el-image
                style="height: 46px; cursor: pointer"
                :src="codeUrl"
                fit="contain"
              ></el-image>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="短信验证码" prop="smsCode">
          <div class="flexBox">
            <el-input
              v-model="ruleForm.smsCode"
              @keyup.enter="submitForm('ruleForm')"
              style="width: 280px; margin-right: 30px"
            >
              <em slot="prefix">
                <SvgIcon iconClass="yzm" /> </em
            ></el-input>
            <el-button
              type="text"
              :disabled="countdown > 0"
              @click.prevent="getYzm"
            >
              {{ countdown > 0 ? `重新获取(${countdown}s)` : "点击获取验证码" }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="width: 100%; margin-top: 10px; font-size: 18px"
            @click.prevent="submitForm('ruleForm')"
            >{{ dialogRegister.zczh ? "注册账号" : "重置密码" }}</el-button
          >
        </el-form-item>
      </el-form>

      <div class="foot">
        <el-button type="text" @click="goBack">返回登录</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { clientRegister, getSmsCode, resetPass, captchaImage } from "@/api/index.js";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
export default {
  name: "Login",
  data() {
    var checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // if (this.ruleForm.password !== "") {
        //   // this.$refs.ruleForm.validateField("password");
        // }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value != this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      codeUrl: "",
      activeName: "mima",
      countdown: 0,
      ruleForm: {
        password: "",
        password1: "",
        username: "",
        smsCode: "",
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        username: [{ validator: checkPhone, trigger: "blur" }],
        password1: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    getCode() {
      captchaImage().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.ruleForm.uuid = res.uuid;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.ruleForm));
          let funct = null;
          if (this.dialogRegister.zczh) {
            funct = clientRegister;
          } else {
            funct = resetPass;
          }
          params.username = encrypt(params.username + "");
          params.password = encrypt(params.password);
          params.password1 = "";
          funct(params).then(({ code, msg }) => {
            if (code == 200) {
              this.$store.commit("SETREGISTER", {
                dialog: false,
                zczh: true,
              });
              this.$alert("即将为您转登录", "您已成功注册电池之家—拍卖平台！", {
                confirmButtonText: "确定",
                callback: () => {
                  this.$store.commit("SETLOGIN", true);
                },
              });
            } else {
              this.$message.error(msg);
              this.getCode();
            }
          });
        }
      });
    },
    goBack() {
      this.$store.commit("SETREGISTER", {
        dialog: false,
        zczh: true,
      });
      this.$store.commit("SETLOGIN", true);
    },
    getYzm() {
      if (!this.ruleForm.username) {
        return this.$message.error("请输入先输入手机号");
      }
      if (!this.ruleForm.code) {
        return this.$message.error("请输入先输入验证码");
      }


      let param = {
        mobile: this.ruleForm.username,
        uuid: this.ruleForm.uuid,
        code: this.ruleForm.code,
      };
      getSmsCode(param).then(({ code, msg }) => {
        if (code !== 200) {
          this.$message.error(msg);
        }else {
          this.countdown = 60;
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
        }
      });

    },
  },
  watch: {
    "$store.state.user.dialogLogin": {
      handler(newVal) {
        if (newVal) {
          this.getCode();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      dialogRegister: (state) => state.user.dialogRegister,
    }),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__headerbtn .el-dialog__close{
  color: rgba(0, 0, 0, 0.4);
}
/deep/.el-dialog__headerbtn{
  font-size: 24px;
}
/deep/ .el-dialog__header {
  padding-left: 40px;
}
/deep/ .el-tabs__item {
  font-size: 18px;
}
/deep/ .el-form--label-top .el-form-item__label {
  font-weight: 400;
  font-size: 16px;
  color: #000000d9;
}
/deep/ .el-dialog__title {
  width: 235px;
  height: 40px;
  display: block;
  background: url("../../assets/images/dczj_paimai.png");
  background-size: cover;
}

/deep/.el-checkbox__label {
  color: rgba(0, 0, 0, 0.6);
}
/deep/.svg-icon {
  width: 20px !important;
  height: 20px !important;
}
/deep/.el-input--prefix .el-input__inner {
  padding-left: 40px;
}
/deep/.el-input__prefix {
  left: 12px;
}
.login-container {
  padding: 0 20px;
  // margin-top: -30px;
  .title {
    font-size: 16px;
    width: 70px;
    height: 30px;
    border-bottom: 4px solid #0dac65;
    margin-bottom: 10px;
  }
  .flexBox {
    display: flex;
    align-items: center;
  }
  /deep/ .el-form-item__label {
    padding-bottom: 0;
    height: 34px;
  }
  .foot {
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
</style>
