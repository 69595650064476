//axios进行二次封装
import axios from "axios";
//进度条
import nprogress from "nprogress";
//因为进度条样式咱们没有引入
import "nprogress/nprogress.css";
//引入Vuex仓库模块
import store from "@/store";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
//创建axios实例[创建出来的实例即为axios，只不过可以配置一些东西]
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  //设置请求时间（5S）
  timeout: 5000,
  credentials: true,
  proxy: true,
});

//请求拦截器:在发请求之前可以检测到，可以干一些事情
instance.interceptors.request.use((config) => {
  //如果不叫做userTempid字段，服务器获取不到
  //config配置对象，这里面有请求头
  //config，请求拦截器的一个配置对象
  // if(store.state.shopcart.userTempId){
  //     config.headers.userTempId = store.state.shopcart.userTempId;
  // }
  //用户身份token
  if (store.state.user.token) {
    config.headers["Authorization"] = "Bearer " + store.state.user.token; // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  //检测到程序发请求，请求拦截器可以检测到，进度条开始动
  nprogress.start();
  return config;
});
// let this = this;
//响应拦截器：服务器的数据已经返回了，可以干一些事情
instance.interceptors.response.use(
   (res)=> {
    // console.log(res.data.code);
    const code = res.data.code;
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code == 401) {
      // this.$message({
      //   type: "warning",
      //   message: "请重新登录！",
      // });
      store.commit("SETLOGIN", true);
    } else {
      //简化服务器返回的数据格式
      //服务器数据返回进度条结束
      nprogress.done();
      return res.data;
    }
  },
  (error) => {
    //终止promise链
    return Promise.reject(error);
  }
);
//对外暴露二次封装的axios
export default instance;
