import { importTemplatePub } from "@/api/index";
// 金额加千分符
export function formatMoneyString(moneyString) {
  // 将金额字符串转换为数字
  const amount = parseFloat(moneyString);

  // 检查是否成功转换为数字
  if (isNaN(amount)) {
    return "---";
  }

  // 使用 toLocaleString() 方法添加千分符并保留两位小数
  const formattedAmount = amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedAmount;
}

/**
 * 阿拉伯数字转换为简体中文的数字字符串
 * @param {number|string} num 阿拉伯数字，支持数字型和字符串型输入
 * @returns {string}      简体中文的数字字符串
 */
export function numberToChinese(num) {
  const CHINESE_NUMBERS = "零一二三四五六七八九";
  const CHINESE_UNITS = ["", "十", "百", "千", "万"];
  const CHINESE_SECTION = [
    "",
    "万",
    "亿",
    "兆",
    "京",
    "垓",
    "秭",
    "穰",
    "沟",
    "涧",
    "正",
    "载",
    "极",
    "恒河沙",
    "阿僧祇",
    "那由他",
    "不可思议",
    "无量",
    "大数",
  ];

  // 对输入进行处理
  if (typeof num === "string" && /^\d+$/.test(num)) {
    num = parseInt(num, 10);
  } else if (typeof num !== "number" || Number.isNaN(num)) {
    return "";
  }

  // 处理特殊情况：0 ~ 9
  if (num < 10) {
    return CHINESE_NUMBERS.charAt(num);
  }

  // 对数字按万、亿、兆等分节
  const nums = [];
  while (num > 0) {
    nums.push(num % 10000);
    num = Math.floor(num / 10000);
  }

  // 处理每个数字节
  let chineseString = "";
  for (let i = nums.length - 1; i >= 0; i--) {
    const section = nums[i];
    let sectionString = "";

    // 处理数字节内的每一个数字
    for (let j = 0; j < 4; j++) {
      const digit = section % 10;
      section = Math.floor(section / 10);

      if (digit > 0) {
        sectionString =
          CHINESE_NUMBERS.charAt(digit) + CHINESE_UNITS[j] + sectionString;
      }
    }

    // 处理数字节的数量单位
    if (sectionString !== "") {
      sectionString += CHINESE_SECTION[nums.length - 1 - i];
    }

    chineseString = sectionString + chineseString;
  }

  return chineseString;
}

export const download = (url, params,fileName) =>  {
  importTemplatePub(url, params)
    .then((res) => {
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.style.display = "none";

      // 使用默认文件名，设置文件类型为Excel并包含扩展名
      link.href = url;
      link.setAttribute("download", fileName); // 设置文件名为file.xlsx，可以根据需要修改扩展名

      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      ElMessage.error("下载文件出现错误，请联系管理员！");
    });
}