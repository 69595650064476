import store from "@/store";
export default [
  {
    path: "/home",
    //component右侧数值：放置的是一个箭头函数，当这个home路由被访问的时候，才会执行；
    //当用户访问当前Home的时候，我才加载Home路由组件，不访问，不加载当前Home路由组件
    component: () => import("@/pages/Home"),
    meta: { footer: true },
  },
  // 重定向
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/biddingProject",
    component: () => import("@/pages/biddingProject"),
    meta: { footer: true },
  },
  {
    path: "/biddingProject/details",
    component: () => import("@/pages/biddingProject/details.vue"),
  },
  // 公告列表
  {
    path: "/Notice",
    component: () => import("@/pages/Notice/index.vue"),
    meta: { footer: true },
  },
  // 公告详情
  {
    path: "/Notice/announcementDetails",
    component: () => import("@/pages/Notice/announcementDetails.vue"),
    // meta: { show: true },
  },
  // 规则说明
  {
    path: "/Rule",
    component: () => import("@/pages/Rule"),
    meta: { footer: true },
  },
  // 我的工作台
  {
    path: "/workSpace",
    component: () => import("@/pages/workSpace/index.vue"),
    meta: { show: true },
  },

  // 发起拍卖申请
  {
    path: "/initiateBidApplication",
    component: () => import("@/pages/workSpace/bidApplication.vue"),
    meta: { show: true },
  },

  // 变更管理
  {
    path: "/changeManagement",
    component: () => import("@/pages/workSpace/changeManagement.vue"),
  },

  // 竞拍大厅
  {
    path: "/bidHall",
    name: "bidHall",
    component: () => import("@/pages/bidHall/index.vue"),
  },

  // 合同
  {
    path: "/contract",
    component: () => import("@/pages/contract/index.vue"),
    meta: { show: false },
  },

  // 设置
  {
    path: "/AccountSettings",
    component: () => import("@/pages/AccountSettings"),
    meta: { show: true },
    children: [
      //我的订单二级路由
      {
        path: "/PersonalInformation",
        component: () =>
          import("@/pages/AccountSettings/PersonalInformation.vue"),
        meta: { show: true },
      },
      {
        path: "/AccountManagement",
        component: () =>
          import("@/pages/AccountSettings/AccountManagement.vue"),
        meta: { show: true },
      },
      {
        path: "/RealNameAuthentication",
        component: () =>
          import("@/pages/AccountSettings/RealNameAuthentication.vue"),
        meta: { show: true },
      },
      {
        path: "/AssociatedEnterprise",
        component: () =>
          import("@/pages/AccountSettings/AssociatedEnterprise.vue"),
        meta: { show: true },
      },
      {
        path: "/Information",
        component: () => import("@/pages/AccountSettings/Information.vue"),
        meta: { show: true, admin: true },
      },
      {
        path: "/EnterpriseSeal",
        component: () => import("@/pages/AccountSettings/EnterpriseSeal.vue"),
        meta: { show: true, admin: true },
      },
      {
        path: "/EnterpriseQualification",
        component: () =>
          import("@/pages/AccountSettings/EnterpriseQualification.vue"),
        meta: { show: true, admin: true },
      },
      {
        path: "/personnelManagement",
        component: () =>
          import("@/pages/AccountSettings/personnelManagement.vue"),
        meta: { show: true, admin: true },
      },
      {
        path: "/Account",
        component: () => import("@/pages/AccountSettings/Account.vue"),
        meta: { show: true, admin: true },
        beforeEnter: (to, from, next) => {
          let walletStatus = store.state.user.userInfo.walletStatus;
          if (walletStatus == "1") {
            //walletStatus == "1"代表未开通，2是冻结，0是开通
            next({ path: "/NoAccount" });
          } else {
            next();
          }
        },
      },
      {
        path: "/NoAccount",
        component: () => import("@/pages/AccountSettings/NoAccount.vue"),
        meta: { show: true, admin: true },
      },
      {
        path: "/MyMessage",
        component: () => import("@/pages/AccountSettings/MyMessage.vue"),
        meta: { show: true },
      },
      {
        path: "/AccountSettings",
        redirect: "/PersonalInformation",
      },
    ],
  },
];
