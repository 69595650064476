import { getAreaList } from "@/api";
//登录与注册模块仓库
const state = {
  areaList: [],
};
const mutations = {
  SET_AREALIST(state, areaList) {
    state.areaList = areaList;
  },
};

const actions = {
  //获取区域列表
  GetAreaList({ commit }, data) {
    getAreaList().then((res) => {
      let arr = res || [];
      if (!arr.length) {
        return false;
      }
      arr.forEach((item) => {
        item.value = item.label = item.name;
        if (item.children) {
          item.children.forEach((item) => {
            item.value = item.label = item.name;
          });
          // item.children.unshift({
          //   label: "全部",
          //   value: "全部",
          // });
        }
      });
      commit("SET_AREALIST", arr);
    });
  },
};
const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
