//统一管理项目全部接口的文件
//获取真实服务器的接口的数据利用当前axios
import request from "./request";

//登录
export const clientLogin = (data) =>
  request({ url: "/client/login", method: "post", data });
  
//用户退出
export const logout = () => request({ url: "/logout", method: "get" });

//验证图片
export const captchaImage = () =>
  request({ url: "/captchaImage", method: "get" });

//注册
export const clientRegister = (data) =>
  request({ url: "/client/register", method: "post", data });

//重置密码
export const resetPass = (data) =>
  request({ url: "/client/resetPass", method: "post", data });

//手机验证码
export const getSmsCode = (params) =>
  request({ url: "/getSmsCode", method: "get", params });

//首页图片
export const getBanners = () =>
  request({ url: "/system/banners/list", method: "get" });

//获取用户信息
export const getInfo = () => request({ url: "/client/getInfoRealTime", method: "get" });

//修改用户信息
export const userUpDate = (code,data) =>
  request({ url: "/client/user?smsCode="+code, method: "put", data });

//修密码
export const updatePwd = (data) =>
  request({ url: "/client/user/updatePwd", method: "put", data });


// 获取区域列表
export function getAreaList() {
  return request({
    url: "/system/area/list",
    method: "get",
  });
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

// 公用下载文件接口
export const importTemplatePub = (url,params) => {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
    headers:  { 'Content-Type': 'application/x-www-form-urlencoded' },
    params:params
  })
}