import { clientLogin, getInfo, logout } from "@/api";
import { setToken, getToken, clearToken } from "@/utils/token";
//登录与注册模块仓库
const state = {
  token: getToken(),
  userInfo: {
    isManuAdmin: "0",
    userName: "",
    nickName: "",
  },
  dialogLogin: false,
  dialogRegister: {
    dialog:false,
    zczh:true
  },
 
};
const mutations = {
  SETTOKEN(state, token) {
    state.token = token;
  },
  //存储用户信息
  GETUSERINGO(state, userInfo) {
    state.userInfo = userInfo;
  },
  //退出登录情况全部用户信息
  USERLOGOUT(state) {
    state.token = "";
    state.userInfo = {};
    //本地存储数据【token】
    clearToken();
  },
  SETLOGIN(state, val) {
    state.dialogLogin = val;
  },
  SETREGISTER(state, val) {
    state.dialogRegister = val;
  },
};
const actions = {
  //登录业务
  async userLogin({ commit }, data) {
    //当前的这个action，向服务器发起登录请求
    let result = await clientLogin(data);
    if (result.code == 200) {
      //如果仓库,已经存储token,用户一定是登陆了
      commit("SETTOKEN", result.token);
      //持久化存储token
      setToken(result.token);
      return "ok";
    } else {
      //登录失败
      return Promise.reject(result.msg);
    }
  },
  //获取用户信息:只要出发这个action，就可以获取用户信息
  async getUserInfo({ commit }) {
    //为什么用户已经登录：但是获取不到用户信息，因为获取用户信息需要携带token
    //这样服务器才知道你是谁，才会返回相应的用户额信息
    let result = await getInfo();
    if (result.code == 200) {
      commit("GETUSERINGO", result.user);
      return "ok";
    } else {
      return Promise.reject(new Error("faile"));
    }
  },
  //退出登录
  async userLogout({ commit }) {
    //发请求通知服务器销毁token
    let result = await logout();
    if (result.code == 200) {
      commit("USERLOGOUT");
    }
  },
};
const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
